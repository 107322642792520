import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ETeamPlayerStatus from "../../enums/TeamPlayerStatus";
import TournamentService from "../../services/TournamentService";
import ITournament from "../Interfaces/Tournament";
import ITournamentTeam from "../Interfaces/TournamentTeam";
import ITournamentTeamMember from "../Interfaces/TournamentTeamMember";
import Modal from "./Modal";
import IUserDetails from "../Interfaces/UserDetails";
import IGameAccount from "../Interfaces/GameAccount";
import { Link } from "react-router-dom";
import GAMES from "../../constants-games";
import Multiselect from "multiselect-react-dropdown";
import AddSingleGameAccountModal from "../Modals/AddGameAccountModal/AddSingleGameAccountModal";
import UserService from "../../services/UserService";
import LoadingButton from "./LoadingButton/LoadingButton";

type Props = {
  member: ITournamentTeamMember;
  tournament: ITournament;
  team: ITournamentTeam;
  onReloadTournament: (tournament?: ITournament | undefined) => void;
};

interface IDisplayValues {
  id: string | undefined;
  game: string | undefined;
  console: string | undefined;
  username: string | undefined;
  displayName?: string;
}

const TeamStatusDropdown = (props: Props) => {
  const { member, tournament, team } = props;
  const [showModal, setShowModal] = useState<{
    show: boolean;
    status?: number;
  }>({ show: false });
  const [showAddModal, setShowAddModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(member.status);

  const [gameAccountNeeded, setGameAccountNeeded] = useState(true);
  const [displayValues, setDisplayValues] = useState<Array<IDisplayValues>>([]);
  const [selectedValue, setSelectedValue] = useState<Array<IDisplayValues>>([]);
  const [gameAccount, setGameAccount] = useState<IGameAccount>();
  const [selectedUser, setSelectedUser] = useState<IUserDetails>();

  const statusOptions: Array<{ key: string; value: number }> = [
    { key: "Pending", value: 0 },
    { key: "Accepted", value: 1 },
    // { key: "Rejected", value: 2 },
  ];

  const changeStatus = (member: ITournamentTeamMember, event: any) => {
    if (!tournament) {
      return;
    }
    setShowModal({ show: true, status: event.target.value });
  };

  useEffect(() => {
    setGameAccountNeeded(
      tournament?.gameAccountNeeded && member.gameAccount === null
    );

    if (tournament?.gameAccountNeeded && member.gameAccount === null) {
      if (member) {
        UserService.getUser(member.userId)
          .then((Ruser) => {
            setSelectedUser(Ruser); // Imposta selectedUser
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    } else if (tournament?.gameAccountNeeded) {
      setGameAccount(member.gameAccount);
    }
  }, [tournament, member]);

  // Nuovo useEffect che parte solo quando selectedUser cambia
  useEffect(() => {
    if (
      selectedUser &&
      selectedUser?.gameAccounts &&
      selectedUser?.gameAccounts.length > 0
    ) {
      const gameAccount = selectedUser.gameAccounts.filter(
        (account: IGameAccount) =>
          tournament?.console.includes(account.console || "") &&
          account.game === tournament?.game
      );
      const displayValues: Array<IDisplayValues> = [...gameAccount];
      displayValues.forEach(
        (account) =>
          (account.displayName = `USERNAME: ${account.username} - ID: ${account.id} - PLATFORM: ${account.console}`)
      );
      setDisplayValues(displayValues);
      setSelectedValue([displayValues[0]]);
      setGameAccount(gameAccount[0]);
    }
  }, [selectedUser, tournament]);

  const onSelect = (event: any) => {
    let { displayName, ...newData } = event[0];
    setGameAccount(newData);
  };

  const onRemove = () => {
    setGameAccount(undefined);
  };

  const confirmChangeStatus = (status?: number) => {
    if (!status) {
      return;
    }
    setLoading(true);
    TournamentService.changeTeamMemberStatus(
      tournament.id,
      team.partyId,
      member.userId,
      Number(status),
      gameAccount
    )
      .then((Rtournament) => {
        toast.success("status changed");
        setLoading(false);
        setDefaultValue(status);
        setShowModal({ show: false });
        props.onReloadTournament(Rtournament);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const onCloseModal = () => {
    confirmChangeStatus(1);
    setShowAddModal(false);
    setShowModal({ show: false });
  };

  return (
    <>
      <div className="skp-form-control">
        {!showModal.show && (
          <select
            defaultValue={defaultValue}
            disabled={Number(member.status) === 2}
            onChange={(event) => changeStatus(member, event)}
          >
            {statusOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.key}
              </option>
            ))}
          </select>
        )}
      </div>

      <Modal
        loading={loading}
        size="medium"
        title="Change Status"
        show={showModal.show}
        onClose={onCloseModal}
      >
        <div className="container">
          <div className="space-divider-20"></div>
          <div className="columns">
            {showModal.status && (
              <>
                <div className="column col-12 skp-text-primary flex-centered">
                  Do you want to change player status to "
                  {ETeamPlayerStatus[showModal.status]}" ?
                </div>
                {gameAccountNeeded && (
                  <>
                    <div className="space-divider-20"></div>
                    <div className="column col-12 col-xs-12">
                      <h6 className="skp-text-light text-uppercase flex-centered">
                        Select game account to proceed
                      </h6>
                      {displayValues.length > 0 && (
                        <div className="skp-form-control flex-centered">
                          <Multiselect
                            options={displayValues} // Options to display in the dropdown
                            selectedValues={selectedValue} // Preselected value to persist in dropdown
                            displayValue="displayName" // Property name to display in the dropdown options
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            singleSelect={true}
                            closeIcon="cancel"
                            placeholder="Select a game account"
                            avoidHighlightFirstOption={true}
                          />
                        </div>
                      )}
                      {displayValues.length === 0 && (
                        <div>
                          <div
                            className="skp-link-primary skp-text-default flex-centered"
                            onClick={() => setShowAddModal(true)}
                          >
                            <i className="las la-plus-circle"></i> Add an
                            account for {GAMES[tournament?.game].NAME}
                          </div>
                          <div className="space-divider-20"></div>
                        </div>
                      )}
                      <p className="skp-text-label skp-text-light">
                        To manage your game accounts go to the{" "}
                        <Link
                          className="skp-link"
                          to={`/users/${selectedUser?.id}/settings/usersettings`}
                        >
                          User Setting's page
                        </Link>
                      </p>
                    </div>
                    <div className="column col-2 hide-xs"></div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="space-divider"></div>
          <div className="columns">
            <div className="space-divider"></div>
            <div className="column col-6">
              <LoadingButton
                label="Cancel"
                className="skp-btn skp-btn-alert skp-text-small"
                disabled={loading}
                onBtnClick={onCloseModal}
              />
            </div>
            <div className="column col-6 text-right">
              <LoadingButton
                label="Change Status"
                className="skp-btn skp-btn-ternary skp-text-small"
                disabled={loading || !gameAccount}
                onBtnClick={() => confirmChangeStatus(showModal.status)}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showAddModal}
        onClose={() => setShowAddModal(false)}
        title="Add Game Account"
        size="large"
      >
        <>
          {selectedUser && (
            <AddSingleGameAccountModal
              tournament={tournament}
              selectUser={selectedUser}
              closeModal={onCloseModal}
            ></AddSingleGameAccountModal>
          )}
        </>
      </Modal>
    </>
  );
};

export default TeamStatusDropdown;
