import Multiselect from "multiselect-react-dropdown";
import { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import TeamService from "../../../services/TeamService";
import { UserContext } from "../../Context/UserContext";
import ITeam from "../../Interfaces/Team";
import ITournament from "../../Interfaces/Tournament";
import IUserDetails from "../../Interfaces/UserDetails";
import TeamRow from "./TeamRow";
import UserSearchDropdown from "../User/UserSearchDropdown";

type Props = {
  tournament: ITournament;
  members?: string[];
  game: string;
  removeCheckbox?: boolean;
  onAddMember: (member: string, user: IUserDetails) => void;
};

const TeamColumn = (props: Props) => {
  const { tournament, members, game, removeCheckbox } = props;
  const { user } = useContext(UserContext);
  const [teams, setTeams] = useState<Array<ITeam>>([]);
  const [team, setTeam] = useState<ITeam>();
  const [value, setValue] = useState<Array<ITeam>>([]);
  const [paginatedItems, setPaginatedItems] = useState<Array<string>>([]);

  useEffect(() => {
    TeamService.getAllTeams({ members: user?.id }).then((teams) => {
      setTeams(teams);
      setTeam(teams[0]);
      setValue([teams[0]]);

      let items: Array<string> = [];
      const index =
        teams[0]?.members?.length >= 10 ? 10 : teams[0]?.members?.length;
      for (let i = 0; i < index; i++) {
        items.push(teams[0]?.members[i]);
      }
      setPaginatedItems(items);
    });
    return () => {};
  }, [user]);

  const setTeamList = (teams: Array<ITeam>) => {
    if (teams.length === 0) {
      setPaginatedItems([]);
      setTeam(undefined);
    } else {
      let items: Array<string> = [];
      const index =
        teams[0]?.members?.length >= 10 ? 10 : teams[0]?.members?.length;

      for (let i = 0; i < index; i++) {
        items.push(teams[0].members[i]);
      }

      setPaginatedItems(items);
      setTeam(teams[0]);
    }
  };

  const fetchMoreData = () => {
    if (team) {
      setTimeout(() => {
        setPaginatedItems([
          ...paginatedItems,
          team.members[paginatedItems.length],
        ]);
      }, 100);
    }
  };

  const inviteUser = (theUser: IUserDetails) => {
    props.onAddMember(theUser?.id!, theUser);
  };
  return (
    <>
      <UserSearchDropdown setUser={inviteUser} />
    </>
  );
};

export default TeamColumn;
