type KStatus = {
  [key: number]: string;
};

interface KContant {
  APP_URL: string;
  API_URL: string;
  GOOGLE_STORAGE_URL: string;
  WORDPRESS_API_URL: string;
  KEYCLOAK_JSON_URL: string;
  CONSOLE_OPTIONS: Array<any>;
  SOCIAL_PLATFORMS: Array<any>;
  MATCH_STATUS: KStatus;
  TOURNAMENT_STATUS: KStatus;
  TEAM_USER_ROLE: KStatus;
  INVITE_STATUS: KStatus;
  GAME_ACCOUNTS_LABELS: any;
  MESSAGES: any;
  TLY_TOKEN: string;
  SKILLPRO_ROLES: Array<any>;
  BANNER_URL: any;
}

const constant: KContant = {
  APP_URL: `${process.env.REACT_APP_URL}`,
  API_URL: `${process.env.REACT_APP_API_URL}`,
  GOOGLE_STORAGE_URL: `${process.env.REACT_APP_GOOGLE_STORAGE_URL}`,
  WORDPRESS_API_URL: `${process.env.REACT_APP_WORDPRESS_API_URL}`,
  KEYCLOAK_JSON_URL: `${process.env.REACT_APP_KEYCLOAK_JSON_URL}`,

  CONSOLE_OPTIONS: [
    { key: "PC", value: "PC" },
    { key: "Xbox One", value: "XBOX_ONE" },
    { key: "Xbox Series X", value: "XBOX_SERIES_X" },
    { key: "PS4", value: "PS4" },
    { key: "PS5", value: "PS5" },
    { key: "Switch", value: "SWITCH" },
    { key: "Mobile", value: "MOBILE" },
    { key: "Live", value: "LIVE" },
  ],

  SKILLPRO_ROLES: [
    { key: "Site Owner", value: "SiteOwner" },
    { key: "Site Admin", value: "SiteAdmin" },
    { key: "Tournament Creator", value: "TournamentCreator" },
    { key: "Tournament Admin", value: "TournamentAdmin" },
    { key: "Tester", value: "Tester" },
    { key: "Competition Creator", value: "CompetitionCreator" },
    { key: "Brand Creator", value: "BrandCreator" },
  ],

  SOCIAL_PLATFORMS: [
    { key: "Facebook", value: "FACEBOOK" },
    { key: "Twitter", value: "TWITTER" },
    { key: "Instagram", value: "INSTAGRAM" },
    { key: "Twitch", value: "TWITCH" },
    { key: "YouTube", value: "YOUTUBE" },
  ],

  GAME_ACCOUNTS_LABELS: {
    FIFA22: "FIFA22",
    APEX_LEGENDS: "APEX_LEGENDS",
    POKEMON_UNITE: "POKEMON_UNITE",
    CLASH_OF_CLANS: "CLASH_OF_CLANS",
    YUGIOH_MASTER_DUEL: "YUGIOH_MASTER_DUEL",
    VALORANT: "VALORANT",
    CLASH_ROYALE: "CLASH_ROYALE",
    CLASH_ROYALE_WITH_BRACKET: "CLASH_ROYALE_WITH_BRACKET",
    LEAGUE_OF_LEGENDS: "LEAGUE_OF_LEGENDS",
    EAFC24: "EAFC24",
    EAFC25: "EAFC25",
    MARVEL_SNAP: "MARVEL_SNAP",
    GENERIC_GAME: "GENERIC_GAME",
    WARZONE: "WARZONE",
    BRAWL: "BRAWL",
    RIVALS: "RIVALS",
  },

  MESSAGES: {
    LOGIN: {
      JOIN_TOURNAMENT: "Log In or Sign Up to join the Tournament",
      IMPORT_TOURNAMENT: "Log In or Sign Up to import the Tournament",
      SESSION_EXPIRED: "Session has expired! Log In or Sign Up",
      TEAM_PLAYERS: "Log In or Sign Up to see team's players",
    },
  },

  MATCH_STATUS: {
    0: "To be played",
    1: "Ongoing",
    2: "Waiting Moderation",
    3: "Completed",
  },

  TOURNAMENT_STATUS: {
    0: "upcoming",
    1: "ongoing",
    2: "completed",
  },

  INVITE_STATUS: {
    0: "Pending",
    1: "Accepted",
    2: "Rejected",
  },

  TEAM_USER_ROLE: {
    0: "Player",
    1: "Reserve",
    2: "Coach",
  },

  TLY_TOKEN: "qSnNmCWUyG3wmvVLPWod6oJTeHamalRlGEmxBfA2eLPepUIfG4MPHKBQhMVG",

  BANNER_URL: {
    FORTNITE:
      "https://storage.googleapis.com/skillpro-bucket/6oMLnhvWklrZE5L.webp",
    FIFA22:
      "https://storage.googleapis.com/skillpro-bucket/YolgpSnMWlfUihE.jpeg",
    EAFC24:
      "https://storage.googleapis.com/skillpro-bucket/Z3XFsdZgBoRPNyU.jpg",
    EAFC25:
      "https://storage.googleapis.com/skillpro-bucket/KNa9YrrRHnAlJAQ.jpeg",
    APEX_LEGENDS:
      "https://storage.googleapis.com/skillpro-bucket/z8PyOLy7DJEuSJI.jpg",
    POKEMON_UNITE:
      "https://storage.googleapis.com/skillpro-bucket/DFOgtb4KMEdwRg8.jpg",
    CLASH_OF_CLANS:
      "https://storage.googleapis.com/skillpro-bucket/7LJiRqvM6WdD1YL.jpg",
    YUGIOH_MASTER_DUEL:
      "https://storage.googleapis.com/skillpro-bucket/G3iMV8Bhti910ZK.jpg",
    VALORANT:
      "https://storage.googleapis.com/skillpro-bucket/FNcOesoCb1MhDCF.webp",
    CLASH_ROYALE:
      "https://storage.googleapis.com/skillpro-bucket/KHvFi7XyhfVYmEE.jpeg",
    CLASH_ROYALE_WITH_BRACKET:
      "https://storage.googleapis.com/skillpro-bucket/KHvFi7XyhfVYmEE.jpeg",
    LEAGUE_OF_LEGENDS:
      "https://storage.googleapis.com/skillpro-bucket/6d6UtFqer221b33.webp",
    MARVEL_SNAP:
      "https://storage.googleapis.com/skillpro-bucket/ixFZu5AcIkchfCu.webp",
    PUBG_MOBILE:
      "https://storage.googleapis.com/skillpro-bucket/bD2Ti2QIniS5q7y.jpg",
    WARZONE:
      "https://storage.googleapis.com/skillpro-bucket/thumb-1920-1071145.jpg",
    GENERIC_GAME:
      "https://storage.googleapis.com/skillpro-bucket/51bXcsGBupY5BaC.webp",
  },
};
//
export default constant;
